:root {
  --tinder-red: #ff4458;
  --white1: #ffffff;
  --white2: #eeeeee;
  --white3: #c0bebe;
  --grey1: #404040;
  --grey2: #3b3b3b;
  --grey3: #212426;
  --grey4: #181a1b;
  --black: #000000;
}

.light {
  --main-background: var(--white2);
  --card-background: var(--white1);
  --accent-color: var(--tinder-red);
  --title-color: var(--grey1);
  --text-color1: var(--black);
  --text-color2: var(--black);
  --input-background: var(--white1);
  --input-outline: var(--white3);
  --descriptor-icon: 0;
  --descriptor-icon-fg: lighten;
}

.dark {
  --main-background: var(--grey3);
  --card-background: var(--grey4);
  --accent-color: var(--tinder-red);
  --title-color: var(--white2);
  --text-color1: var(--white1);
  --text-color2: var(--white3);
  --input-background: var(--grey2);
  --input-background: var(--grey4);
  --input-outline: var(--grey2);
  --descriptor-icon: 220%;
  --descriptor-icon-fg: darken;
}

@font-face {
  font-family: TinderFont;
  src: url("../fonts/font.ttf");
}

::selection {
  background-color: var(--tinder-red);
  color: var(--grey4);
}

html,
body {
  height: 100%;
}

body {
  display: flex;
  margin: 0 20px;
  background-color: var(--main-background);
  color: var(--title-color);
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell,
    Noto Sans, sans-serif, Helvetica, Arial, Segoe UI Emoji, Segoe UI Symbol,
    Apple Color Emoji, Twemoji Mozilla, Noto Color Emoji, EmojiOne Color,
    Android Emoji;
  transition: all 0.6s ease-in-out;
}

input,
select,
button {
  padding: 4px;
  border-radius: 5px;
  border-width: 1px;
  font-size: 16px;
  outline: var(--accent-color);
  transition: all 0.1s ease-in-out;
}

input,
select {
  margin: 2px;
  border: 2px solid var(--input-outline);
  background-color: var(--card-background);
  color: var(--text-color2);
}

button {
  padding: 5;
  border: 2px solid var(--accent-color);
  cursor: pointer;
}

input:hover,
select:hover,
button:hover {
  transition: all 0.2s ease-in-out;
}

input:hover {
  border-radius: 25px;
}

input:focus,
select:focus {
  border: 2px solid var(--accent-color);
}

label {
  display: inline-block;
  margin: 7px 0 7px 0;
  width: 80px;
}

h3 {
  margin-top: 15px;
}

h4,
h5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

a {
  color: var(--text-color1);
  text-decoration: none;
}

.input-container {
  display: flex;
  align-items: center;
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
  margin-right: 5px;
  border-radius: 5px;
  border: 1px solid var(--input-outline);
  font-size: 10px;
  user-select: none;
}

.checkbox.empty {
  background-color: var(--input-background);
  transition: all 0.1s ease-in-out;
}

.checkbox.checked {
  background-color: greenyellow;
  transition: all 0.2s ease-in-out;
}

.checkbox.empty:before {
  content: "";
}

.checkbox.checked:before {
  content: "\002714";
}

.arrow {
  border: solid var(--text-color1);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 8px;
  font-size: 16px;
  cursor: pointer;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.user,
.displayed-user {
  display: flex;
  position: relative;
  width: 140px;
  height: 200px;
  margin: 15px;
  border-radius: 15px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}

.user {
  cursor: pointer;
  color: var(--white1);
}

.user:before {
  content: "";
  position: absolute;
  border-radius: 15px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, transparent 0%, black 220%);
}

.user:hover {
  transition: all 0.4s ease-in-out;
  transform: scale(1.2);
  box-shadow: 0 2.8px 2.2px 3px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px 3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px 3px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px 3px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px 3px rgba(0, 0, 0, 0.086),
    0 100px 80px 3px rgba(0, 0, 0, 0.12);
}

.user-header {
  margin: 0 0 3px 6px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.user-age {
  margin-left: "5px";
  color: var(--white3);
}

.user-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.displayed-user-badge {
  margin-left: 7px;
}

.interest,
.descriptor {
  display: flex;
  margin: 3px;
  border-radius: 25px;
  border: 1px solid var(--accent-color);
  color: var(--text-color2);
}

.interest {
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.descriptor-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 2px 2px 5px;
  border-radius: 25px 0 0 25px;
}

.descriptor-icon img {
  height: 23px;
  filter: brightness(var(--descriptor-icon));
}

.descriptor-value {
  padding: 5px 10px 5px 5px;
}

#root {
  width: 100%;
  height: 100%;
}

#app-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

#logo-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 35px 0 15px 0;
  cursor: pointer;
}

#logo {
  height: 40px;
  width: 40px;
  margin-top: 2px;
  filter: brightness(1.2);
}

#title {
  display: inline-block;
  font-size: 45px;
  font-weight: bolder;
  margin: 0;
  color: var(--title-color);
  font-family: TinderFont;
  transition: all 0.6s ease-in-out;
}

#theme-switch-btn {
  position: fixed;
  top: 0;
  right: 0;
  margin: 5px;
  border: 0;
  font-size: 25px;
  background-color: transparent;
  transition: all 0.6s ease-in-out;
}

#filter-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

#filter-input-container {
  display: flex;
  flex-direction: column;
}

#search-btn-container {
  display: flex;
  margin: 2px;
}

#search-btn {
  margin-left: 3px;
  margin-right: 0;
  padding-left: 7px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  background-color: var(--accent-color);
}

#search-btn:hover {
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
}

#advanced-btn {
  margin-left: 0;
  padding-right: 7px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-color: var(--accent-color);
  background-color: var(--card-background);
  color: var(--text-color2);
}

#advanced-btn:hover {
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
}

#navigation-container {
  margin-left: 10px;
}

#back-btn,
#forward-btn {
  display: none;
  margin: 5px;
}

#name-checkbox {
  display: none;
}

#advanced-search {
  display: none;
  margin-bottom: 10px;
}

#custom-logic {
  display: none;
  width: 250px;
}

#user-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  width: fit-content;
  max-width: 100%;
  padding: 25px 0 15px 0;
}

#blur-users {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(15px) grayscale(50%) brightness(50%);
}

#displayed-user-container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

#displayed-user {
  display: flex;
  flex-direction: column;
  max-width: 70%;
  height: fit-content;
  max-height: 70%;
  margin: 15px;
  padding: 15px 0 0 15px;
  border-radius: 10px;
  background-color: var(--card-background);
  color: var(--title-color);
  transition: all 0.6s ease-in-out;
}

#displayed-user-header {
  display: flex;
  justify-items: center;
  align-items: center;
  transition: all 0.6s ease-in-out;
}

#displayed-user-bio,
#fav-song,
#fav-artists {
  max-width: 1000px;
  color: var(--text-color2);
  transition: all 0.6s ease-in-out;
}

#displayed-user-photos,
#displayed-user-instagram-photos,
#displayed-user-interests,
#displayed-user-descriptors {
  display: flex;
  flex-wrap: wrap;
}

#displayed-user-content {
  height: 100%;
  padding-right: 15px;
  overflow-y: scroll;
}

@media (max-width: 800px) {
  .user,
  .displayed-user {
    width: 100px;
    height: 160px;
    margin: 15px;
  }

  #blur-users {
    height: 150%;
  }

  #user-grid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
}
